.div-li {
  display: inline-grid;
}
.input-r {
  margin: 0.5em;
  margin-left: 0;
  padding: 1em;
  display: block;
  overflow: scroll;
}
.label-r {
  margin: 0.1em;
  padding: 0.5em;
  display: block;
}
.btn-add {
  width: 50%;
  padding: 1em;
  float: inline-end;
  margin: 1em;
}
.note {
  width: 90%;
  padding: 11px;
  padding-left: 1px;
  text-align: justify;
  max-height: 111px;
  overflow: auto;
  line-height: 1.5em;
}
