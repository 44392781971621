.form {
  width: fit-content;
  margin: 5px;
  padding: 7px;
  margin-right: auto;
  margin-left: auto;
  overflow: auto;
}
.input-ac {
  margin: 0.5em;
  margin-left: 0;
  padding: 1em;
  display: block;
  width: 333px;
}
.label-ac {
  display: block;
  margin: 0.1em;
  padding: 0.5em;
}
.btn-add {
  width: 25%;
  padding: 1em;
  float: inline-end;
  margin: 1em;
}

@media all and (max-width: 400px) {
  .input-ac,
  .btn-add {
    width: 100%;
  }
  .btn-add {
    float: inline-start;
  }
}
