h2,
h3 {
  padding: 11px 1px;
  margin: auto;
  text-align: left;
}
small {
  color: grey;
  font-size: medium;
}
p {
  margin: 1px;
  padding: 3px;
}
.contact {
  color: grey;
}
.slogan {
  color: #ccab00;
  font-size: 1.5em;
  margin: 7px 0px;
}
.discription {
  padding: 11px;
  padding-left: 1px;
  text-align: justify;
  max-height: 111px;
  overflow: auto;
  line-height: 1.5em;
}

.candidate-info {
  transition: ease all 0.5s;
  width: 91%;
  float: left;
  margin-left: 1%;
  padding: 17px;
  background-color: #e5e5e5;
  border-radius: 11px;
}
.vote-btn-container {
  transition: ease all 0.5s;
  height: 100%;
  width: 17%;
  float: right;
  align-items: center;
  justify-content: center;
  padding: 17px;
}
.vote-bth {
  width: 100%;
  height: 55px;
  font-size: 157%;
}
@media all and (max-width: 900px) {
  .candidate-info,
  .vote-btn-container {
    width: 100%;
    height: 100%;
  }
}
