.label-home {
  padding: 0.5em;
}
.input-home {
  margin-top: 1em;
  margin-left: 7px;
  margin-right: 7px;
  padding: 1em;
  min-width: 300px;
}
.input-home,
.label-home {
  display: block;
  margin-bottom: 11px;
}
